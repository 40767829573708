.Header {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  @media screen and (max-width: 500px) {
    height: calc(100vh - 74px);
  }

  svg {
    position: absolute;
    height: auto;
    transform-origin: bottom center;
    z-index: 100;
  }

  .img-logo {
    z-index: 10;
  }

  .img-bg-layer_bottom {
    bottom: 0;
    height: unset;
  }

  .header-container {
    width: 100%;
    min-height: 100%;
    max-width: 1100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &.desktop-version {
      .img-cow {
        max-width: 38%;
        bottom: 2vh;
        left: 1vw;
      }

      .img-pig {
        max-width: 30%;
        bottom: 3vh;
        right: 1vw;
      }

      .img-logo {
        padding-bottom: 5vh;
        width: 90vw;
        height: 70vh;
        max-width: 800px;
        transform-origin: top center;
      }

      .img-rosemary {
        max-width: 27%;
        top: -5%;
        left: -2%;
      }

      .img-bread {
        max-width: 16%;
        top: 35%;
        left: 10%;
      }

      .img-carrot {
        max-width: 28%;
        top: 2%;
        right: 2%;
      }

      .img-parsley {
        max-width: 8%;
        right: 10%;
        top: 50%;
      }
    }


    &.mobile-version {
      .img-cow {
        max-width: 72%;
        bottom: 3%;
        left: 4%;
      }

      .img-pig {
        max-width: 58%;
        top: 2%;
        right: 2%;
      }

      .img-logo {
        max-width: 86%;
        padding-bottom: 3rem;
      }

      .img-rosemary {
        max-width: 50%;
        top: 0;
        left: -4%;
      }

      .img-bread {
        display: none;
      }

      .img-carrot {
        display: none;
      }

      .img-parsley {
        display: none;
      }

      .img-bg-layer_bottom {
        bottom: 0;
      }
    }
  }

  .arrow-down {
    width: unset;
    height: unset;
    position: absolute;
    bottom: 2vw;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s ease-in-out;
    animation: blink 2s ease-in-out infinite alternate-reverse;

    @media screen and (max-width: 500px) {
      min-width: 2.5rem;
      width: 4%;
      bottom: 3%;
      right: 16%;
      left: unset;
    }
  }

  @keyframes blink {
    0% {
      transform: translate(-50%, 0);
    }
    95% {
      transform: translate(-50%, -0.5rem);
    }
    100% {
      transform: translate(-50%, -0.5rem);
    }
  }
}
