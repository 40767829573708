@font-face {
  font-family: "Renner*.Medium";
  src: local('Renner'), url('./res/fonts/Renner-Book.otf') format("opentype");
}

@font-face {
  font-family: "Renner*.Thin";
  src: local('Renner'), url('./res/fonts/Renner-Light.otf') format("opentype");
}

@font-face {
  font-family: "Renner*.Book";
  src: local('Renner'), url('./res/fonts/Renner-Book.otf') format("opentype");
}

@font-face {
  font-family: Caveat;
  src: local('Caveat'), url('./res/fonts/Caveat-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: Avenir, 'Renner*.Thin', sans-serif;

  h1, h2, h3, h4 {
    font-family: 'Renner*.Medium', Avenir, sans-serif;
    text-align: center;
  }

  h1 {
    font-size: 7rem;
  }

  h2 {
    font-size: 2.5rem;
    padding: 3rem 0 1rem;

    @media screen and (max-width: 500px) {
      font-size: 1.8rem;
    }
  }

  p {
    max-width: 600px;
    line-height: 1.8rem;
  }

  a {
    font-family: 'Renner*.Book', sans-serif;
    background-image: linear-gradient(90deg, #DED0CC, #DED0CC);
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 100% 40%;
    transition: 0.2s ease-in-out;
    text-decoration: unset;
    color: unset;

    &:hover {
      background-size: 100% 100%;
    }
  }

  svg {
    pointer-events: none;
  }
}
