.FisherAnimation {
  margin: 3rem 0;
  width: 100%;
  max-width: 500px;

  @media screen and (max-width: 500px){
    margin: 0 0 1.5rem 0;
  }

  p {
    font-family: 'Caveat', sans-serif;
    font-size: 1.6rem;
    text-align: center;
    margin: 0.5rem 0 0 0;
  }

  svg {
    max-width: 100%;
    height: auto;
    overflow: hidden;

    #waves-upper {
      animation: small-shift 6s ease-in-out infinite alternate-reverse;
    }

    #waves-bottom {
      animation: large-shift 6s ease-in-out infinite alternate-reverse;
    }

    #person-container {
      animation: sway 3s ease-in-out infinite alternate-reverse;
      transform-origin: 36% 80%;
    }

    #perch-container {
      animation: pull-fish-up 12s ease-in-out infinite;
      transform: translateY(100%);
    }
  }

  @keyframes pull-fish-up {
    0% {
      transform: translateY(100%);
    }

    20% {
      transform: translateY(100%);
    }

    55% {
      transform: translateY(0%);
    }

    75% {
      transform: translateY(0%);
    }

    90% {
      transform: translateY(100%);
    }
  }

  @keyframes small-shift {
    from {
      transform: translateX(-4px);
    }
    to {
      transform: translateX(0px);
    }
  }

  @keyframes large-shift {
    from {
      transform: translateX(5px);
    }
    to {
      transform: translateX(-5px);
    }
  }

  @keyframes sway {
    from {
      transform: rotate(1.5deg);
    }
    to {
      transform: rotate(-1.5deg);
    }
  }
}
