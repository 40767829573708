.Introduction {
  .process-container {
    position: relative;

    .decoration-garlic {
      position: absolute;
      right: -3rem;
    }
  }

  @media screen and (max-width: 500px) {
    .process-container {
      .decoration-garlic {
        right: 0rem;
        transform: rotate(260deg) scale(0.9);
      }
    }
  }
}
