.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  width: 100%;

  &:before {
    display: flex;
    width: 100vw;
    margin-bottom: 15vh;
    background-color: #DED0CC;
    height: 3rem;
    content: '';
  }
}
