.Events {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .parsley-img {
    position: absolute;
    top: 4rem;
    left: -3rem;
    transform: translate(-70%, 100%);
  }

  @media screen and (max-width: 500px) {
    .parsley-img {
      left: -2rem;
      transform: translate(-25%, -15%) scale(0.8);
    }
  }
}
