.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    padding: unset;
    margin: 2rem 0 1rem;
  }

  img {
    width: 100%;
  }

  .contact-container {
    background-color: #fff;
    padding: 0.5rem 0 2rem;
    text-align: center;
    z-index: 100;
  }

  .gratitude-container {
    background-color: #fff;
  }

  &.desktop-version {
    margin-top: 6rem;

    .parallax-box {
      display: grid;
      max-width: 95%;
      grid-template-rows: 160px 40px 120px max-content 120px;
      grid-template-columns: 1fr 3fr 4fr 2fr 9fr 4fr 3fr;

      .contact-container {
        grid-row: 1/3;
        grid-column: 2/5;
      }

      .image-container {
        grid-row: 2/7;
        grid-column: 3/7;
      }

      .chicken-img {
        z-index: 1000;
        grid-row: 6/8;
        grid-column: 1/4;
        width: 100%;
      }

      .onion-img {
        height: 100%;
        width: 100%;
        grid-row: 1/3;
        grid-column: 6/8;
      }
    }

    .gratitude-container {
      padding: 3rem 7rem 5rem;
      width: fit-content;
      margin: 4rem 0;

      div {
        margin-left: 1.5rem;
      }
    }
  }

  &.mobile-version {
    margin-top: 2rem;

    .parallax-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: unset;

      svg {
        display: none;
      }

      .contact-container {
        width: 100%;
        margin-bottom: 4rem;
      }
    }

    .image-container {
      transform: translateY(-1rem);
    }

    .gratitude-container {
      padding: .5rem 0 2rem;
      margin-top: 4rem;
      width: 100%;

      div {
        margin-left: 2rem;
      }
    }
  }
}
